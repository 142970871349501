import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

export default ({ children }) => {
  return (
    <ReactMarkdown plugins={[gfm]} linkTarget="_blank">
      {children}
    </ReactMarkdown>
  );
};
