import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React from "react";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCs2M6Pw-6KJjt3wlNu40RyU5F1ZJbA8AY",
  authDomain: "hyperconvo.firebaseapp.com",
  projectId: "hyperconvo",
  storageBucket: "hyperconvo.appspot.com",
  messagingSenderId: "526687639093",
  appId: "1:526687639093:web:3842473a2cf1a71378c37c",
  measurementId: "G-RN06EZ633S",
};

firebase.apps.length || firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const firestore = firebase.firestore();

const FirebaseContext = React.createContext({
  firebase,
  auth,
  firestore,
});

const base = {
  convoCreate: async (convoId) => {
    const { uid, displayName, photoURL } = auth.currentUser;
    const convo = firebase.firestore().collection("convos").doc(convoId);
    return Promise.all([
      convo.set({ name: convoId, public: true, uid }),
      convo
        .collection("members")
        .doc(uid)
        .set({ role: "owner", displayName, photoURL }),
    ]);
  },
  messageCreate: ({ message, convoId }) => {
    const convo = firebase.firestore().collection("convos").doc(convoId);
    const { uid, displayName, photoURL } = auth.currentUser;

    return convo.collection("messages").add({
      ...message,
      uid,
      displayName,
      photoURL,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  },
  submissionCreate: ({ convoId, message, option }) => {
    const { uid, displayName, photoURL } = auth.currentUser;
    const submissions = firebase
      .firestore()
      .collection("convos")
      .doc(convoId)
      .collection("messages")
      .doc(message.id)
      .collection("submissions");
    return submissions.doc(uid).set({
      displayName,
      uid,
      photoURL,
      option: option.text,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  },
  onSubmitOpenQuestion: ({ convoId, message, response }) => {
    const { uid, displayName, photoURL } = auth.currentUser;
    const submissions = firebase
      .firestore()
      .collection("convos")
      .doc(convoId)
      .collection("messages")
      .doc(message.id)
      .collection("submissions");
    return submissions.doc(uid).set({
      displayName,
      photoURL,
      response,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  },
};

export { auth, firestore, firebase, base, FirebaseContext };
