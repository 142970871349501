import { useState, useRef, useEffect } from "react";
import AnonymousUserProfile from "../../shared/AnonymousUserProfile";
import StyledText from "../shared/StyledText";
import { auth } from "../../shared/firebase";

const DisplayName = ({ user, displayName, setDisplayName }) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div className="">
      {user.displayName && !isEditing ? (
        <div className="flex space-x-1">
          <div>{isEditing || user.displayName}</div>
          {user.isAnonymous && (
            <button
              className="text-gray-400"
              onClick={(e) => {
                e.preventDefault();
                setIsEditing(!isEditing);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </button>
          )}
        </div>
      ) : (
        <input
          className="resize-none p-1 focus:outline-none focus:ring-1 focus:ring-gray-600 focus:ring-current rounded-md"
          type="text"
          value={displayName || user.displayName}
          onChange={(event) => setDisplayName(event.target.value)}
          placeholder="Display name"
        ></input>
      )}
    </div>
  );
};

export default ({ message, submissions, onSubmit }) => {
  const [response, setResponse] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [isReplying, setIsReplying] = useState(false);
  const replyEl = useRef(null);

  useEffect(() => {
    if (isReplying) {
      replyEl.current.focus();
    }
  }, [isReplying, replyEl]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    if (!response) return setIsReplying(false);
    if (displayName) {
      auth.currentUser.updateProfile({ displayName });
    }
    //Fixme displayname should be updated before submit.
    onSubmit(message, response);
    setDisplayName("");
    setResponse("");
    setIsReplying(false);
  };

  const detectSubmit = (e) => {
    if (e.key == "Enter" && e.metaKey == true) {
      handleSubmit(e);
    }
  };

  return (
    <>
      <div className="leading-6 mt-2">
        <StyledText>{message.prompt}</StyledText>
      </div>
      <div className="flex flex-col space-y-2 pl-4 mt-2">
        {submissions?.map((submission, i) => (
          <div className="flex flex-row space-x-2" key={i}>
            {submission.photoURL ? (
              <img
                src={submission.photoURL}
                title={submission.displayName}
                className="h-6 w-6 rounded-full flex-shrink-0 bg-gray-300 shadow-inner"
              />
            ) : (
              <div className="flex-shrink-0">
                <AnonymousUserProfile
                  size="sm"
                  title={submission.displayName}
                />
              </div>
            )}
            <p>{submission.response}</p>
          </div>
        ))}
        {isReplying || (
          <button
            className="flex flex-row items-center space-x-2 text-gray-600 p-1 -m-1 rounded-md bg-gray-200 bg-opacity-40"
            onClick={() => {
              setIsReplying(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              opacity="0.6"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              />
            </svg>
            <div>Reply</div>
          </button>
        )}
        <div className={`flex flex-col w-full ${!isReplying && "hidden"}`}>
          <form onSubmit={handleSubmit}>
            <textarea
              className="w-full resize-none p-1 focus:outline-none focus:ring-1 focus:ring-gray-600 focus:ring-current rounded-md"
              ref={replyEl}
              placeholder="Add your response here"
              value={response}
              onKeyDown={detectSubmit}
              onChange={(event) => setResponse(event.target.value)}
            ></textarea>
            <div className="flex justify-between text-gray-600">
              <DisplayName
                user={auth.currentUser}
                displayName={displayName}
                setDisplayName={setDisplayName}
              />
              <button
                className="text-gray-500"
                type="submit"
                onClick={handleSubmit}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
