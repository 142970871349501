import { useState, useContext } from "react";
import { FirebaseContext } from "../shared/firebase";
import AnonymousUserProfile from "../shared/AnonymousUserProfile";

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { firebase, auth } = useContext(FirebaseContext);
  const signOut = () => {
    isOpen && setIsOpen(false);
    if (auth.currentUser) return auth.signOut();
  };
  return (
    <div
      className={`relative inline-block text-left ${
        auth.currentUser || "hidden"
      }`}
    >
      <div>
        <button
          type="button"
          className="flex items-center justify-center w-full rounded-md  px-2 py-2 font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
          id="options-menu"
          onClick={() => setIsOpen(!isOpen)}
        >
          {auth.currentUser?.photoURL ? (
            <img
              src={auth.currentUser.photoURL}
              className="h-8 w-8 rounded-full"
            />
          ) : (
            <AnonymousUserProfile />
          )}
        </button>
      </div>
      <div
        className={
          "origin-top-right absolute right-0 mt-2 w-56 z-50 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5" +
          (isOpen ? "" : " hidden")
        }
      >
        <div
          className="py-1 "
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <a
            href="#"
            className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
            role="menuitem"
            onClick={signOut}
          >
            <span className="flex flex-col">
              <span>Sign Out</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
