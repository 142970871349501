import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";

import {
  auth,
  firebase,
  base,
  firestore,
  FirebaseContext,
} from "../shared/firebase";
import { useRoutes, navigate } from "hookrouter";

import MessageInput from "./MessageInput";
import MessageList from "./MessageList";
import ConvoNavbar from "./ConvoNavbar";

const uidConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /c/meta after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: "/c/meta",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    "anonymous",
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const SignInView = ({ convoSnapshot }) => {
  if (!convoSnapshot) return <div>Loading...</div>;
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /c/meta after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/c/meta",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ...(convoSnapshot.exists ? ["anonymous"] : []),
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <h1 className="text-xl">Sign In</h1>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      <p className="text-center">
        {convoSnapshot.exists
          ? "Sign in to continue."
          : "Sign in to create Convo."}
      </p>
    </div>
  );
};

const SignedInView = ({ convoSnapshot }) => {
  const [user, loading, error] = useAuthState(firebase.auth());

  return (
    <>
      <MessageList convo={convoSnapshot}></MessageList>
      {convoSnapshot.data()?.uid === user?.uid ? (
        <MessageInput convo={convoSnapshot}></MessageInput>
      ) : (
        <div className="flex flex-col text-center text-gray-400 h-24 justify-center">
          You have to be an owner to post polls.
        </div>
      )}
    </>
  );
};

const ConvoNewView = ({ convoId }) => {
  const [user, loading, error] = useAuthState(firebase.auth());
  const canCreateConvo = !user.isAnonymous;
  return (
    <div className="flex flex-col items-center justify-center space-y-2 p-2 h-full">
      {canCreateConvo && (
        <button
          className="border-2 border-gray-600 bg-gray-200 hover:bg-gray-300 p-2"
          onClick={async () => {
            await base.convoCreate(convoId);
            navigate(`/c/${convoId}`);
          }}
        >
          Create Convo
        </button>
      )}
      <div className="text-gray-400">
        {canCreateConvo ? (
          "Create the Convo and claim this Convo link."
        ) : (
          <span>
            You can not create a convo as a Guest user.
            <br /> Please sign out and sign in with a real identity.
          </span>
        )}
      </div>
    </div>
  );
};

export default ({ convoId }) => {
  const [user, loading, error] = useAuthState(firebase.auth());

  const [convoSnapshot, convoLoading, convoError] = useDocument(
    firebase.firestore().doc(`convos/${convoId}`)
  );
  return (
    <div className="flex flex-col h-screen">
      {!convoLoading && (
        <ConvoNavbar convoSnapshot={convoSnapshot} convoId={convoId} />
      )}
      {user ? (
        convoLoading ? (
          "Loading..."
        ) : convoSnapshot.exists ? (
          <SignedInView convoSnapshot={convoSnapshot} />
        ) : (
          <ConvoNewView convoId={convoId} />
        )
      ) : (
        <SignInView convoSnapshot={convoSnapshot} />
      )}
    </div>
  );
};
