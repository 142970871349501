import { useState, useContext } from "react";
import { FirebaseContext } from "../shared/firebase";

import { textParser } from "../shared/helpers";

const MessageInput = ({ convo }) => {
  const { auth, base } = useContext(FirebaseContext);
  const [messageContent, setMessageContent] = useState("");
  const parsedMessage = textParser(messageContent);

  const postMessage = async (e) => {
    if (e) e.preventDefault();
    if (!messageContent) return;
    await base.messageCreate({ convoId: convo.id, message: parsedMessage });
    setMessageContent("");
  };

  const detectSubmit = (e) => {
    if (messageContent && e.key == "Enter" && e.metaKey == true) {
      postMessage();
    }
  };

  return (
    <div className="shadow-2xl w-full max-w-xl mx-auto mb-4 rounded-lg bg-gray-200">
      <form onSubmit={postMessage}>
        <div className="flex flex-col">
          <textarea
            className="flex-grow resize-y py-2 px-4 rounded-t-lg bg-gray-100 focus:bg-gray-50 focus:ring-1 focus:ring-gray-600 focus:outline-none shadow-inner"
            placeholder="Add message or convo prompt."
            cols=""
            rows="4"
            value={messageContent}
            onKeyDown={detectSubmit}
            onChange={(event) => setMessageContent(event.target.value)}
          ></textarea>
        </div>
        <div className="flex items-center rounded-sm p-1 justify-between text-gray-600">
          <button
            className="flex-shrink flex items-center justify-center h-6 w-6 rounded hover:bg-gray-200"
            type="submit"
            onClick={postMessage}
          >
            <svg
              className="h-4 w-4 transform rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default MessageInput;
