import { useContext, useEffect, useRef, Fragment } from "react";
import {
  useCollectionData,
  useCollection,
} from "react-firebase-hooks/firestore";
import { firestore, FirebaseContext } from "../shared/firebase";
import OpenQuestionMessage from "./messages/OpenQuestionMessage";
import PlainMessage from "./messages/PlainMessage";
import MultipleChoiceMessage from "./messages/MultipleChoiceMessage";

const renderTime = (ts) => {
  return new Date(ts).toLocaleTimeString();
};

const MessageDetail = ({
  message,
  onSelectOption,
  onSubmitResponse,
  submissionsRef,
}) => {
  const MessageBody = ({ message }) => {
    const [submissions, loading, error] = useCollectionData(submissionsRef);

    if (message.type === "text") return <PlainMessage message={message} />;
    if (message.type === "openQuestion")
      return (
        <OpenQuestionMessage
          message={message}
          submissions={submissions}
          onSubmit={onSubmitResponse}
        />
      );
    if (message.type === "multipleChoice")
      return (
        <MultipleChoiceMessage
          message={message}
          submissions={submissions}
          onSelectOption={onSelectOption}
        />
      );
    return <div>{JSON.stringify(message)}</div>;
  };

  return (
    <div className="flex px-2 py-2 w-full justify-center">
      <div className="w-full max-w-xl bg-gray-100 p-4 rounded-lg">
        <div className="flex items-center w-full max-w-xl space-x-2">
          <img
            className="h-6 w-6 rounded-full flex-shrink-0 bg-gray-300 shadow-inner"
            src={message.photoURL}
          />
          <div className="flex justify-between w-full">
            <div className="font-semibold">{message.displayName}</div>
            <div className="ml-2 flex text-gray-500">
              {renderTime(message.createdAt?.toDate() || new Date())}
            </div>
          </div>
        </div>
        <MessageBody message={message} />
      </div>
    </div>
  );
};

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const MessageList = ({ convo }) => {
  const { base } = useContext(FirebaseContext);
  const messagesRef = convo.ref.collection("messages");
  const query = messagesRef.orderBy("createdAt").limit(25);

  const [messages] = useCollectionData(query, { idField: "id" });

  const onSelectOption = (message, option) => {
    base.submissionCreate({ convoId: convo.id, message, option });
  };

  const onSubmitResponse = (message, response) => {
    base.onSubmitOpenQuestion({ convoId: convo.id, message, response });
  };

  return (
    <div className="flex flex-col flex-grow overflow-y-scroll w-full bg-gray-200">
      <div className="flex flex-col py-6 flex-grow text-gray-600 w-full items-center justify-end">
        <div className="text-center">
          <h2>Start of everything</h2>
          <p>Let's start the convo.</p>
        </div>
      </div>
      {messages &&
        messages.map((m, i) => (
          <MessageDetail
            message={m}
            onSelectOption={onSelectOption}
            onSubmitResponse={onSubmitResponse}
            submissionsRef={messagesRef.doc(m.id).collection("submissions")}
            key={i}
          />
        ))}
      <AlwaysScrollToBottom />
    </div>
  );
};

export default MessageList;
