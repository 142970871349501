import { useContext, useEffect, useRef, Fragment } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import AnonymousUserProfile from "../../shared/AnonymousUserProfile";
import StyledText from "../shared/StyledText";

import { firebase } from "../../shared/firebase";

const SubmissionSummaryView = ({ message, option, submissions = [] }) => {
  const optionSubmissions = Object.keys(submissions)
    .map((key) => submissions[key])
    .filter((s) => s["option"] === option.text);
  return (
    <div className="flex flex-row space-x-1">
      <div className="flex ml-1 font-medium justify-center bg-gray-400 h-6 w-6 rounded-full text-gray-50">
        {optionSubmissions.length}
      </div>
      {optionSubmissions.map((submission, i) => (
        <div
          key={i}
          className="flex items-center font-medium justify-center text-gray-50"
          title={submission.displayName}
        >
          {submission.photoURL ? (
            <img
              src={submission.photoURL}
              className="h-6 w-6 rounded-full flex-shrink-0 bg-gray-300"
            />
          ) : (
            <AnonymousUserProfile size="sm" title={submission.displayName} />
          )}
        </div>
      ))}
    </div>
  );
};

export default ({ message, submissions = [], onSelectOption }) => {
  const [user] = useAuthState(firebase.auth());

  return (
    <>
      <div className="leading-6 mt-2">
        <StyledText>{message.prompt}</StyledText>
      </div>
      <div className="flex flex-col space-y-2 mt-2">
        {message.options.map((option, i) => {
          const selected = submissions.find(
            (s) => option.text === s.option && s.uid === user.uid
          );
          return (
            <Fragment key={i}>
              <button
                className={`flex items-center justify-center px-2 py-1 max-w-md ${
                  selected ? "bg-gray-500 text-gray-50" : "bg-gray-300"
                } hover:bg-gray-400 rounded-lg`}
                key={i}
                onClick={() => onSelectOption(message, option)}
              >
                <div className="flex-1 text-left">{option.text}</div>
              </button>
              <SubmissionSummaryView
                message={message}
                submissions={submissions}
                option={option}
              />
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
