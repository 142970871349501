export const textParser = (text) => {
  const lines = text.trim().split(/\r?\n/);
  if (lines[0].endsWith("?")) {
    if (lines[1]?.trim() === "[__]" || lines[1] === undefined) {
      return { type: "openQuestion", prompt: lines[0] };
    }
    const options = extractOptions(lines.slice(1));
    return {
      type: "multipleChoice",
      prompt: lines[0],
      options,
    };
  }
  return { type: "text", text };
};

const extractOptions = (lines) => {
  return lines.map((l) => {
    return { text: l.split(/\d\)/).pop().trim() };
  });
};
