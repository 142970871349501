import React from "react";
import DropdownMenu from "./DropdownMenu";

const ConvoNavbar = ({ convoSnapshot, convoId }) => {
  const convo = convoSnapshot.data();
  return (
    <div className="flex justify-between items-center flex-shrink-0 h-16 bg-gray-100 shadow-lg px-4 rounded-t-xl">
      <div>
        <h1 className="text-xl font-bold leading-none">&gt; {convoId}</h1>
        {convoSnapshot.exists && (
          <span className="">
            A convo about Hyperconvo...
            {/* TODO: description needed */}
          </span>
        )}
        {!convoSnapshot.exists && <span>Convo not yet created.</span>}
      </div>
      <DropdownMenu />
    </div>
  );
};

export default ConvoNavbar;
