import React from "react";
import { useRoutes } from "hookrouter";
import "./App.css";

import ConvoDetailView from "./components/ConvoDetailView";

import {
  auth,
  firebase,
  base,
  firestore,
  FirebaseContext,
} from "./shared/firebase";

const routes = {
  "/c/:convoId": ({ convoId }) => <ConvoDetailView convoId={convoId} />,
};

function App() {
  const routeResult = useRoutes(routes);
  return (
    <FirebaseContext.Provider value={{ auth, firebase, firestore, base }}>
      <div className="bg-gray-200 border-t-8 border-green-600 text-gray-800">
        {routeResult || (
          <div className="flex h-screen items-center justify-center text-center p-2 text-2xl text-gray-400">
            This is still an experiment. Everything can be reset without any
            notice.
          </div>
        )}
      </div>
    </FirebaseContext.Provider>
  );
}

export default App;
